import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';

const pairs = {
  'BTC.B': 'BTC',
  'BTC': 'BTC.B'
}

class CreateSwap extends Component{

  state = {
    amount: 0,
    amount_err: '',
    from: 'BTC',
    to: 'BTC.B',
    destination: '',
    swapSlot: null
  }

  componentDidMount() {
  }

  handleAmount = (e) => {
    this.setState({ amount: e.target.value });
    if (e.target.value === '')
        this.setState({ amount_err: 'Required Field' });
    else if (parseFloat(e.target.value) > 0)
        this.setState({ amount_err: '' });
    else
        this.setState({ amount_err: 'Amount should be greater than 0' });
  }

  handleDestination = (e) => {
    this.setState({ destination: e.target.value })
  }

  handleFrom = (e) => {
    this.setState({ to: pairs[e.target.value] || 'BTC.B', from: e.target.value});
  }

  submitCreateSwap = () => {
    const { to, from, amount, destination } = this.state
    this.props.createSwap(amount, from, to, destination)
      .then((data) => this.setState({ swapSlot: data }))
  }

  render() {
    console.log(this.state.swapSlot)
    return (
      <AUX>
        <div className="container-fluid"> 
          <div className="row">
            <div className="col-lg-6">
              <div className="card" style={{ marginBottom: 0 }}>
                  <div className="card-body">

                      <h4 className="mt-0 header-title">Create Swap</h4>
                      <p className="text-muted m-b-30 font-14">Initiate a trustless swap and convert your coins
                        to/from a Swingby tethered equivalent.</p>

                      <form action="#">
                          <div className="form-group">
                              <label>Amount</label>
                              <div>
                                  <input type="text" className="form-control" value={this.state.amount} onChange={this.handleAmount}
                                      placeholder="Min value is 6" />
                                  <span id="err">{this.state.amount_err}</span>
                              </div>
                          </div>
                          <div className="form-group">
                              <label>From</label>
                              <div>
                                  <select className="custom-select" defaultValue="BTC" onChange={this.handleFrom}>
                                      {
                                        Object.keys(pairs).map((p) => {
                                          return (
                                            <option value={p}>{p}</option>
                                          )
                                        })
                                      }
                                  </select>
                              </div>
                          </div>
                          <div className="form-group">
                              <label>To</label>
                              <div>
                                <input disabled className="form-control" type="text" value={this.state.to}/>
                              </div>
                          </div>
                          <div className="form-group">
                              <label htmlFor="form-destination-input">Destination</label>
                              <div>
                                  <input className="form-control" type="text" id="form-destination-input"
                                    placeholder={"Your " + this.state.to + " address"} value={this.state.destination}
                                    onChange={this.handleDestination}/>
                              </div>
                          </div>
                          <div className="form-group m-b-0">
                              <div>
                                  <button onClick={this.submitCreateSwap} type="button" className="btn btn-primary waves-effect waves-light">
                                      Submit
                                  </button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
            </div>

            <div className="col-lg-6">
              {
                (!this.state.swapSlot) && (
                  <div className="card m-b-20" style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'column',
                        justifyContent: 'center', textAlign: 'center', color: 'lightgrey' }}>
                        <i class="ion ion-arrow-swap float-right" style={{ fontSize: 50, marginBottom: 20 }}/>
                        <p>Submit to initiate a swap</p>
                      </div>
                  </div>
                )
              }
              {
                (this.state.swapSlot) && (
                  <div className="card" style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
                    <div className="card-body">
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}} >
                        <h4 className="header-title">Send</h4>
                        <p><b>{this.state.swapSlot.amountIn} {this.state.swapSlot.currencyIn}</b> to <b>{this.state.swapSlot.addressIn}</b></p>
                        <h4 className="header-title">Receive</h4>
                        <p><b>{this.state.swapSlot.calculation.receive_amount} {this.state.swapSlot.currencyOut}</b> to <b>{this.state.swapSlot.addressOut}</b></p>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default CreateSwap;   
