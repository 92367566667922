import React from "react";

import {
  Card,
  CardBody,
} from 'reactstrap';
import { Progress } from 'reactstrap';
import {
  convertToCurrentStr
} from '../../redux/conversion/utils'

import BnbImage from '../../assets/images/BNB.svg'
import BtcBImage from '../../assets/images/BTC.B.svg'
import BtcImage from '../../assets/images/BTC.svg'

const testnet = true

const btcTestnetExplorer = 'https://live.blockcypher.com/btc-testnet/address/%{address}/'
const btcMainnetExplorer = 'https://live.blockcypher.com/btc/address/%{address}/'
const bnbTestnetExplorer = 'https://testnet-explorer.binance.org/address/%{address}'
const bnbMainnetExplorer = 'https://explorer.binance.org/address/%{address}'

const btcTestnetAddress = 'mr6ioeUxNMoavbr2VjaSbPAovzzgDT7Su9'
const btcMainnetAddress = 'mr6ioeUxNMoavbr2VjaSbPAovzzgDT7Su9'
const bnbTestnetAddress = 'tbnb1ws2z8n9ygrnaeqwng69cxfpnundneyjze9cjsy'
const bnbMainnetAddress = 'tbnb1ws2z8n9ygrnaeqwng69cxfpnundneyjze9cjsy'

const accounts = [
  {
    symbol: 'BTC',
    explorer: testnet ? btcTestnetExplorer : btcMainnetExplorer,
    address: testnet ? btcTestnetAddress : btcTestnetExplorer
  },
  {
    symbol: 'BNB',
    explorer: testnet ? bnbTestnetExplorer : bnbMainnetExplorer,
    address:  testnet ? bnbTestnetAddress : bnbMainnetAddress
  }
]

function percentage(partialValue, totalValue) {
  return Math.round((100 * partialValue) / totalValue).toString()
}


class VerticalStats extends React.Component {
  render() {
    let swapFailures = 0
    let swapSuccess = 0
    let signing = 0
    let pending = 0

    this.props.swaps.forEach((swap) => {
      const status = swap.status
      if (status.includes('WAITING') || status.includes('PENDING')) {
        pending = pending + 1
        return
      }
      if (status.includes('REFUND')) {
        swapFailures = swapFailures + 1
        return
      }
      if (status.includes('SIGNING') || status.includes('SENDING')) {
        signing = signing + 1
        return
      }
      if (status.includes('COMPLETED')) {
        swapSuccess = swapSuccess + 1
        return
      }
    })
    let swapWarning = null
    if (pending > 33) {
      swapWarning = 'Influx of waiting!'
    }
    if (swapFailures > 33) {
      swapWarning = 'Influx of refunds!'
    }
    const explorerString = accounts.map((account) => {
      return (
        <a key={account.address}
          className="btn btn-dark waves-effect waves-light ml-2"
          href={account.explorer.replace('%{address}', account.address)}
          style={{ padding: '0px 6px', fontSize: 10, marginTop: -6 }}
          ><b>{account.symbol}</b></a>
      )
    })
    let bnbBalance = 0
    let btcBbalance = 0
    let btcBalance = 0
    
    this.props.balances.map((balance) => {
      const symbol = balance.symbol
      const value = Number(Number(balance.value).toFixed(8))
      switch (symbol.toLowerCase()) {
        case 'btc':
          btcBalance += value
          break
        case 'btcb-1de':
        case 'btc.b-918':
        case 'btc.b-888':
          btcBbalance += value
          break
        case 'bnb':
          bnbBalance = value
          break
        default:
      }
    })

    let floatWarning = null
    if (bnbBalance < 2) {
      floatWarning = "BNB low!"
    }
    if (Math.abs(100 - percentage(btcBalance, btcBbalance)) > 50) {
      floatWarning = "Requires re-balance!"
    }
    return (
      <div>
        <Card className="mini-stat bg-light">
            <CardBody className="mini-stat-img">
                <div>
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                      <div className="col-6" style={{ padding: 0, margin: 0 }}>
                        <h6 className="mb-3">Floats <span style={{ fontSize: 12, opacity: 0.5 }}> {explorerString}</span></h6>
                      </div>
                      <div className="col-6" style={{ padding: 0, margin: 0, textAlign: 'right' }}>
                        <p className="text-danger">{floatWarning}</p>
                      </div>
                    </div>
                    {
                      (this.props.balances.length === 0) && (
                        <p>Loading balances from explorer...</p>
                      )
                    }
                    {
                      (this.props.balances.length > 0) && (
                        <div className="row">
                           <div className="col-4" style={{ padding: 0, margin: 0, justifyContent: 'center', flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <img className="mb-1 mt-3" src={BtcImage} alt="BTC" style={{ margin: 'auto' }}/>
                            <p className="mt-0 mb-3" style={{ marginBottom: 17, textAlign: 'center' }}><b>{btcBalance}</b></p>
                           </div>
                           <div className="col-4" style={{ padding: 0, margin: 0, justifyContent: 'center', flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <img className="mb-1 mt-3" src={BtcBImage} alt="BTC.B" style={{ margin: 'auto' }}/>
                            <p className="mt-0 mb-3" style={{ marginBottom: 17, textAlign: 'center' }}><b>{btcBbalance}</b></p>
                           </div>
                           <div className="col-4" style={{ padding: 0, margin: 0, justifyContent: 'center', flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <img className="mb-1 mt-3" src={BnbImage} alt="BNB" style={{ margin: 'auto' }}/>
                            <p className="mt-0 mb-3" style={{ marginBottom: 17, textAlign: 'center' }}><b>{bnbBalance}</b></p>
                           </div>
                        </div>
                      )
                    }
                </div>
            </CardBody>
        </Card>
        <Card className="mini-stat bg-light">
            <CardBody className="mini-stat-img">
                <div>
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                      <div className="col-6" style={{ padding: 0, margin: 0 }}>
                        <h6 className="mb-2">Network</h6>
                      </div>
                      <div className="col-6" style={{ padding: 0, margin: 0, textAlign: 'right' }}>
                        <p className="text-danger">{swapWarning}</p>
                      </div>
                    </div>
                    <p className="mt-0">Last 100 swaps</p>

                    <div>
                      <Progress multi>
                          <Progress bar color="success" value={percentage(swapSuccess, this.props.swaps.length)}></Progress>
                          {/* <Progress bar color="info" value={pending.toString()}></Progress> */}
                          <Progress bar color="warning" value={percentage(signing, this.props.swaps.length)}></Progress>
                          <Progress bar color="danger" value={percentage(swapFailures, this.props.swaps.length)}></Progress>
                      </Progress>
                    </div>
                </div>
            </CardBody>
        </Card>
      </div>
    );
  }
}

VerticalStats.defaultProps = {
  swapStats: {},
  peers: [],
  nodeStatus: {},
  swaps: []
}

export default VerticalStats;

