import {
  RECEIVE_KVSTORE,
  FETCH_KVSTORE,
  RECEIVE_KVSTORE_DONE,
  getApiUrl
} from '../constants';
const axios = require('axios');

export const fetchKvStore = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_KVSTORE })
    return axios.get(`${getApiUrl()}/api/v1/debug/kvstore`)
      .then((results) => {
        dispatch({
          type: RECEIVE_KVSTORE,
          payload: JSON.parse(results.data)
        })
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
        dispatch({ type: RECEIVE_KVSTORE_DONE })
      });
  }
}
