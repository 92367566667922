export const getApiUrl = () => {
  return window.NODE_URL || window.location.origin // "https://testnet-node.swingby.network"
}
export const stakingApi = 'https://testnet-staking-api.swingby.network' // 'http://207.148.123.230:8080'
export const stakingProdApi = 'https://pre-staking-swingby.swingby.network'
export const getBtcFiatRates = 'https://proxy-currency.swingby.network/v2/tickers?symbols=tBTCUSD,tBTCJPY,tBTCEUR,tBTCGBP'

// APP
export const SIDE_BAR = 'side_bar';
export const TOP_BAR = 'top_bar';
export const LOGINPAGE = 'loginpage';
export const REG_PAGE = 'reg_page';
export const SWITCHTOREG = 'switch_reg';
export const SWITCHTOLOGIN = 'switch_login';
export const RECOVER_PASS = 'recover_pass';

// PEERS
export const FETCH_PEERS = 'FETCH_PEERS';
export const RECEIVE_PEERS = 'RECEIVE_PEERS';
export const RECEIVE_PEERS_DONE = 'RECEIVE_PEERS_DONE';

// SWAPS
export const FETCH_NETWORK_SWAPS = 'FETCH_NETWORK_SWAPS';
export const RECEIVE_NETWORK_SWAPS = 'RECEIVE_NETWORK_SWAPS';
export const RECEIVE_NETWORK_SWAPS_DONE = 'RECEIVE_NETWORK_SWAPS_DONE';
export const FETCH_NODE_SWAPS = 'FETCH_NODE_SWAPS';
export const RECEIVE_NODE_SWAPS = 'RECEIVE_NODE_SWAPS';
export const RECEIVE_NODE_SWAPS_DONE = 'RECEIVE_NODE_SWAPS_DONE';
export const FETCH_SWAP_STATS = 'FETCH_SWAP_STATS';
export const RECEIVE_SWAP_STATS = 'RECEIVE_SWAP_STATS';
export const RECEIVE_SWAP_STATS_DONE = 'RECEIVE_SWAP_STATS_DONE';
export const CREATE_SWAP = 'CREATE_SWAP';
export const CREATE_SWAP_DONE = 'CREATE_SWAP_DONE';

// INFO
export const FETCH_NODE_INFO = 'FETCH_NODE_INFO'
export const RECEIVE_NODE_INFO = 'RECEIVE_NODE_INFO'
export const RECEIVE_NODE_INFO_DONE = 'RECEIVE_NODE_INFO_DONE'

// EXPLORERS
export const FETCH_BALANCES = 'FETCH_BALANCES'
export const RECEIVE_BALANCES = 'RECEIVE_BALANCES'
export const RECEIVE_BALANCES_DONE = 'RECEIVE_BALANCES_DONE'

// STAKING
export const FETCH_STAKING_LEADERBOARD = 'FETCH_STAKING_LEADERBOARD'
export const RECEIVE_STAKING_LEADERBOARD = 'RECEIVE_STAKING_LEADERBOARD'
export const RECEIVE_STAKING_LEADERBOARD_DONE = 'RECEIVE_STAKING_LEADERBOARD_DONE'
export const FETCH_REWARDS_LEADERBOARD = 'FETCH_REWARDS_LEADERBOARD'
export const RECEIVE_REWARDS_LEADERBOARD = 'RECEIVE_REWARDS_LEADERBOARD'
export const RECEIVE_REWARDS_LEADERBOARD_DONE = 'RECEIVE_REWARDS_LEADERBOARD_DONE'
export const FETCH_REWARDS_HISTORY = 'FETCH_REWARDS_HISTORY'
export const RECEIVE_REWARDS_HISTORY = 'RECEIVE_REWARDS_HISTORY'
export const RECEIVE_REWARDS_HISTORY_DONE = 'RECEIVE_REWARDS_HISTORY_DONE'
export const FETCH_CURRENT_HOLDINGS = 'FETCH_CURRENT_HOLDINGS'
export const RECEIVE_CURRENT_HOLDINGS = 'RECEIVE_CURRENT_HOLDINGS'
export const RECEIVE_CURRENT_HOLDINGS_DONE = 'RECEIVE_CURRENT_HOLDINGS_DONE'

// CONVERSIONS
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY'
export const FETCH_CONVERSION_RATES = 'FETCH_CONVERSION_RATES'
export const RECEIVE_CONVERSION_RATES = 'RECEIVE_CONVERSION_RATES'
export const RECEIVE_CONVERSION_RATES_DONE = 'RECEIVE_CONVERSION_RATES_DONE'

// DEBUG
export const FETCH_KVSTORE = 'FETCH_KV_STORE'
export const RECEIVE_KVSTORE = 'RECEIVE_KV_STORE'
export const RECEIVE_KVSTORE_DONE = 'RECEIVE_KV_STORE_DONE'
