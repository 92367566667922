import {
  getApiUrl,
  RECEIVE_NETWORK_SWAPS,
  RECEIVE_NETWORK_SWAPS_DONE,
  FETCH_NETWORK_SWAPS,
  FETCH_SWAP_STATS,
  RECEIVE_SWAP_STATS,
  RECEIVE_SWAP_STATS_DONE,
  CREATE_SWAP,
  CREATE_SWAP_DONE,
} from '../constants';
const axios = require('axios');

const V1_SWAPS_ENDPOINT = 'api/v1/swaps'
const V1_SWAPS_QUERY_ENDPOINT = `${V1_SWAPS_ENDPOINT}/query`
const V1_SWAPS_STATS_ENDPOINT = `${V1_SWAPS_ENDPOINT}/stats`
const V1_SWAPS_CREATE_ENDPOINT = `${V1_SWAPS_ENDPOINT}/create`
const V1_SWAPS_CALCULATE_ENDPOINT = `${V1_SWAPS_ENDPOINT}/calculate`

export const fetchNetworkSwaps = (page=1, pageSize=100) => {
  return (dispatch) => {
    dispatch({ type: FETCH_NETWORK_SWAPS })
    return axios.get(`${getApiUrl()}/${V1_SWAPS_QUERY_ENDPOINT}?page=${page}&page_size=${pageSize}`)
      .then(function (response) {
        dispatch({
          type: RECEIVE_NETWORK_SWAPS,
          swaps: response.data.items
        })
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
        dispatch({ type: RECEIVE_NETWORK_SWAPS_DONE })
      });
  }
}

export const fetchSwapStats = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_SWAP_STATS })
    return axios.get(`${getApiUrl()}/${V1_SWAPS_STATS_ENDPOINT}`)
      .then(function (response) {
        dispatch({
          type: RECEIVE_SWAP_STATS,
          stats: response.data
        })
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
        dispatch({ type: RECEIVE_SWAP_STATS_DONE })
      });
  }
}

export const createSwap = (amount, from, to, destination) => {
  const headers = {
    'Content-Type': 'application/json'
  }
  return (dispatch) => {
    dispatch({ type: CREATE_SWAP })
    return axios.post(`${getApiUrl()}/${V1_SWAPS_CALCULATE_ENDPOINT}`, {
      amount,
      currency_from: from,
      currency_to: to,
      address_to: destination,
    }, { headers })
    .then((resCalculate) => {
      resCalculate.data.amount = resCalculate.data.send_amount
      console.log({
        ...resCalculate.data,
        address_to: destination
      })
      return axios.post(`${getApiUrl()}/${V1_SWAPS_CREATE_ENDPOINT}`, {
        ...resCalculate.data,
        address_to: destination
      }, { headers })
        .then(function (response) {
          // console.log(response)
          let swap = response.data
          swap.calculation = resCalculate.data
          dispatch({
            type: CREATE_SWAP,
            // swap,
          })
          return response.data
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
        .finally(function () {
          // always executed
          dispatch({ type: CREATE_SWAP_DONE })
        });
    })
  }
}
