import React from "react";
import {
	Card,
  CardBody,
} from 'reactstrap';
import {
  convertToCurrentStr
} from '../../redux/conversion/utils'
import BtcBImage from '../../assets/images/BTC.B.svg'
import BtcImage from '../../assets/images/BTC.svg'

function truncateText (text, maxLength=25) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength-3) + '...'
  }
  return text
}

class RecentSwaps extends React.Component {
	render() {
    const sym = this.props.conversion.currentCurrency.symbol
		return (
      <Card className="card m-b-20">
        <CardBody style={{ height: 490 }}>
            <div className="row">
              <div className="col-6">
                <h4 className="mt-0 header-title mb-4">Recent Swaps</h4>
              </div>
              <div className="col-6" style={{ textAlign: 'right' }}>
                <a href="https://bridge-testnet.swingby.network/explorer">View explorer ></a>
              </div>
            </div>
            <div className="scrollbar" style={{ overflowY: "scroll", overflowX: 'hidden', height: 410 }}>
              <table className="table mb-0">
                  <tbody>
                    {
                      this.props.swaps.map((swap) => {
                        const date = new Date(swap.timestamp)
                        let icon1 = BtcImage
                        let icon2 = BtcBImage
                        if (swap.currencyIn.includes('BTC.B')) {
                          icon1 = BtcBImage
                          icon2 = BtcImage
                        }
                        let bgColor
                        switch (swap.status) {
                          case 'WAITING':
                            bgColor = '#eaebed40'
                            break
                          case 'REFUND':
                            bgColor = '#ec536c54'
                            break
                          case 'SIGNING':
                            bgColor = '#f3ba2f4a'
                            break
                          case 'COMPLETED':
                            bgColor = '#58db834a'
                            break
                          case 'SENDING':
                            bgColor = '#58db834a'
                            break
                          default:
                            bgColor = 'none'
                            break
                        }
                        return (
                          <tr key={JSON.stringify(swap)} style={{ backgroundColor: bgColor }}>
                            <td style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', justifyContent: 'start' }}>
                              <div style={{ width: 30 }}>
                                <img src={icon1} style={{ width: 30 }}  alt="currency_in" className="mr-1"/>
                              </div>
                              <div className="ml-3" style={{ marginTop: -3 }}>
                                <div styles={{ flexDirection: 'column' }}>
                                  <div><p style={{ fontSize: 10, margin: 0 }}>{sym} {convertToCurrentStr(this.props.conversion, 'BTC', swap.amountIn)}</p></div>
                                  <div style={{ marginTop: -4 }}><b>{swap.amountIn}</b></div>
                                </div>
                              </div>
                            </td>
                            <td><i className="mdi mdi-swap-horizontal"></i></td>
                            <td style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', justifyContent: 'start' }}>
                              <div style={{ width: 30 }}>
                                <img src={icon1} style={{ width: 30 }}  alt="currency_in" className="mr-1"/>
                              </div>
                              <div className="ml-3" style={{ marginTop: -3 }}>
                                <div styles={{ flexDirection: 'column' }}>
                                  <div><p style={{ fontSize: 10, margin: 0 }}>{sym} {convertToCurrentStr(this.props.conversion, 'BTC', swap.amountOut)}</p></div>
                                  <div style={{ marginTop: -4 }}><b>{swap.amountOut}</b></div>
                                </div>
                              </div>
                            </td>
                            <td className="inbox-item-date">{date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
              </table>
            </div>
        </CardBody>
      </Card>
		);
	}
}

RecentSwaps.defaultProps = {
  swaps: []
}

export default RecentSwaps;
