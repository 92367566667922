import {
  FETCH_BALANCES,
  RECEIVE_BALANCES,
  RECEIVE_BALANCES_DONE,
} from '../constants';

const initialState = {
  isLoadingBalances: false,
  balances: [
  ]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BALANCES:
      return {
        ...state,
        isLoadingBalances: true,
      }
    case RECEIVE_BALANCES:
      return {
        ...state,
        balances: action.payload,
      }
    case RECEIVE_BALANCES_DONE:
      return {
        ...state,
        isLoadingBalances: false
      }
    default:
      return state
  }
}
