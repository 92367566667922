import React , { Component } from 'react';
import Dashboard from '../MainContent/Dashboard';
import CreateSwap from '../MainContent/CreateSwap';
import KvViewer from '../MainContent/KvViewer';
import { Route, Switch } from 'react-router-dom';

class mainbuilder extends Component{
  render(){
    return(
      <Switch>
        <Route path="/" exact={true} component={Dashboard} />
        <Route path="/create" component={CreateSwap} />
        <Route path="/kv_explorer" component={KvViewer} />
      </Switch>
    );
  }
}

export default mainbuilder;
