import React , { Component } from 'react';
import SwapVolumeChart from './SwapVolumeChart'
import {
	Card,
  CardBody
} from 'reactstrap';
import {
  convertToCurrentStr
} from '../../redux/conversion/utils'

class SwapVolume extends Component{

render(){
  // use slice() to get new copy of array
  const network24Hr = (this.props.stats.network24hrSwapsVolume || []).slice().reverse()
  const participated24Hr = (this.props.stats.participated24hrSwapsVolume || []).slice().reverse()

  const volumeYesterday = network24Hr[network24Hr.length -1] || 0
  let volume7Days = 0
  for (let i = network24Hr.length; i > 0 && i > network24Hr.length - 7; i--){
    volume7Days += network24Hr[i] || 0
  }
  let volume1Month = 0
  for (let i = 0; i < network24Hr.length && i < 30; i++) {
    volume1Month += network24Hr[i] || 0
  }
  const sym = this.props.conversion.currentCurrency.symbol
  return (
    <div className="col-xl-6">
      <Card className="m-b-20">
          <CardBody style={{ height: 490}}>
              <h4 className="mt-0 header-title">Swap Volume</h4>

              <div className="row text-center m-t-20">
                  <div className="col-4">
                      <h5 className="">{sym} {convertToCurrentStr(this.props.conversion, 'BTC', volumeYesterday)}</h5>
                      <p className="text-muted">Yesterday</p>
                  </div>
                  <div className="col-4">
                      <h5 className="">{sym} {convertToCurrentStr(this.props.conversion, 'BTC', volume7Days)}</h5>
                      <p className="text-muted">Last 7 Days</p>
                  </div>
                  <div className="col-4">
                      <h5 className="">{sym} {convertToCurrentStr(this.props.conversion, 'BTC', volume1Month)}</h5>
                      <p className="text-muted">Last 30 Days</p>
                  </div>
              </div>

              <div style={{ margin: 'auto' }}>
                <SwapVolumeChart
                  width={window.innerWidth < 1200 ? window.innerWidth * 0.8 : window.innerWidth * 0.4}
                  height={320}
                  volume1={network24Hr}
                  volume2={participated24Hr}
                />
              </div>
          </CardBody>
      </Card>
    </div>
    );
  }
}

SwapVolume.defaultProps = {
  stats: {}
}

export default SwapVolume;   
