import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Tinycharts from '../../containers/Chartstypes/Tinycharts';
import $ from 'jquery';

class TopChart extends Component {

    componentDidMount() {
        $('.navigation-menu li.has-submenu #my_href').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });
      }

    constructor(props) {
        super(props);
        this.state = {
            Tab: '', SubTab: '', MoreTab: ''
        };
    }

    setActiveTab = (tab,subtab,moretab, e)=> {
        this.setState({ Tab: tab,SubTab: subtab, MoreTab: moretab });
    }

    render() {
        const { node = {} } = this.props.nodeInfo
        const { nodeInfo = {} } = node
        const { testnet = false } = nodeInfo
        const testnetTag = testnet ? <span class="badge badge-info ml-2"> testnet </span> : null

        return (
            <header id="topnav">
            <div className="topbar-main">
            <div className="container-fluid">
                <div className="logo">
                    <Link to="index" className="logo">
                        <img src="assets/images/logo-sm.png" alt=""  style={{marginTop:"20px"}}  className="logo-small" />
                        <img src="assets/images/logo.png" alt="" style={{height: 36}} className="logo-large" />
                    </Link>
                </div>
                <div className="float-right mt-2">
                    <select
                        className="custom-select"
                        value={this.props.conversion.currentCurrency.ticker}
                        onChange={(e) => {
                            this.props.setCurrency(e.target.value)
                        }}>
                        {
                        this.props.conversion.currencies.map((p) => {
                            return (
                            <option key={p} value={p}>{p}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <div className="menu-extras topbar-custom">
                    <ul className="float-right list-unstyled mb-0 ">
                        <li className="menu-item">
                            <Link to="#" className="navbar-toggle nav-link" id="mobileToggle">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </li>    
                    </ul>
                </div>
                <div className="clearfix"></div>
            </div> 
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="page-title-box">
                    {this.state.Tab === 'dashboard' || this.state.Tab === 'index' || this.state.Tab === ''  ?
                        <div>
                            <h4 className="page-title">Dashboard {testnetTag}</h4>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active">
                                    Welcome to the Swingby Node Dashboard
                                </li>
                            </ol>
                        </div>
                        :
                            <div>
                                <h4 className="page-title">{this.state.Tab.charAt(0).toUpperCase() + this.state.Tab.slice(1)}</h4>
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Swingby</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">{this.state.Tab}</a></li>
                                <li className="breadcrumb-item active">{this.state.SubTab}</li>
                                </ol>
                        </div>
                    }
                        
                    <Tinycharts />
                    </div>
                </div>
            </div>
        </div>

        <div className="navbar-custom">
            <div className="container-fluid">
                <div id="navigation">
                    <ul className="navigation-menu">
                        <li className={this.state.Tab === 'dashboard' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'dashboard','','')}>
                            <Link to="/">
                                <i className="ti-dashboard"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li className={this.state.Tab === 'create' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'create','','')}>
                            <Link to="create">
                                <i className="ion-arrow-swap"></i>
                                <span>Create Swap</span>
                            </Link>
                        </li>
                        <li className={this.state.Tab === 'kv_explorer' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'kv_explorer','','')}>
                            <Link to="kv_explorer">
                                <i className="ion-search"></i>
                                <span>KV Explorer</span>
                            </Link>
                        </li>
                        {/* <li className={this.state.Tab === 'network' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'network','','')}>
                            <Link to="network">
                                <i className="ion-network"></i>
                                <span>Network</span>
                            </Link>
                        </li>
                        <li className={this.state.Tab === 'statistics' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'statistics','','')}>
                            <Link to="stats">
                                <i className="ion-stats-bars"></i>
                                <span>Statistics</span>
                            </Link>
                        </li> */}

                        {/*
                        <li className={this.state.Tab == 'email' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-email"></i>Email</Link>
                            <ul className="submenu">
                                <li  className={this.state.SubTab == 'inbox' ? 'active':''} onClick={this.setActiveTab.bind(this, 'email','inbox','')}><Link to="email-inbox">Inbox</Link></li>
                                <li className={this.state.SubTab == 'read' ? 'active':''} onClick={this.setActiveTab.bind(this, 'email','read','')}><Link to="email-read">Email Read</Link></li>
                                <li className={this.state.SubTab == 'compose' ? 'active':''} onClick={this.setActiveTab.bind(this, 'email','compose','')}><Link to="email-compose">Email Compose</Link></li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'ui-element' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-support"></i>UI Elements</Link>
                            <ul className="submenu megamenu">
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'alerts' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','alerts','')}><Link to="ui-alerts">Alerts</Link></li>
                                        <li className={this.state.SubTab == 'buttons' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','buttons','')}><Link to="ui-buttons">Buttons</Link></li>
                                        <li className={this.state.SubTab == 'badge' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','badge','')}><Link to="ui-badge">Badge</Link></li>
                                        <li className={this.state.SubTab == 'cards' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','cards','')}><Link to="ui-cards">Cards</Link></li>
                                        <li className={this.state.SubTab == 'carousel' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','carousel','')}><Link to="ui-carousel">Carousel</Link></li>
                                        <li className={this.state.SubTab == 'dropdowns' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','dropdowns','')}><Link to="ui-dropdowns">Dropdowns</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'grid' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','grid','')}><Link to="ui-grid">Grid</Link></li>
                                        <li className={this.state.SubTab == 'images' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','images','')}><Link to="ui-images">Images</Link></li>
                                        <li className={this.state.SubTab == 'modals' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','modals','')}><Link to="ui-modals">Modals</Link></li>
                                        <li className={this.state.SubTab == 'pagination' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','pagination','')}><Link to="ui-pagination">Pagination</Link></li>
                                        <li className={this.state.SubTab == 'popover-tooltips' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','popover-tooltips','')}><Link to="ui-popover-tooltips">Popover & Tooltips</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'progressbars' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','progressbars','')}><Link to="ui-progressbars">Progress Bars</Link></li>
                                        <li className={this.state.SubTab == 'sweet-alert' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','sweet-alert','')}><Link to="ui-sweet-alert">Sweet-Alert</Link></li>
                                        <li className={this.state.SubTab == 'tabs-accordions' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','tabs-accordions','')}><Link to="ui-tabs-accordions">Tabs &amp; Accordions</Link></li>
                                        <li className={this.state.SubTab == 'typography' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','typography','')}><Link to="ui-typography">Typography</Link></li>
                                        <li className={this.state.SubTab == 'video' ? 'active':''} onClick={this.setActiveTab.bind(this, 'ui-element','video','')}><Link to="ui-video">Video</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'form' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-receipt"></i>Forms</Link>
                            <ul className="submenu">
                                <li className={this.state.SubTab == 'elements' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','elements','')}><Link to="form-elements">Elements</Link></li>
                                <li className={this.state.SubTab == 'validation' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','validation','')}><Link to="form-validation">Validation</Link></li>
                                <li className={this.state.SubTab == 'advanced' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','advanced','')}><Link to="form-advanced">Advanced</Link></li>
                                <li className={this.state.SubTab == 'editors' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','editors','')}><Link to="form-editors">Editors</Link></li>
                                <li className={this.state.SubTab == 'uploads' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','uploads','')}><Link to="form-uploads">Uploads</Link></li>
                                <li className={this.state.SubTab == 'xeditable' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','xeditable','')}><Link to="form-xeditable">Xeditable</Link></li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'more' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-menu-alt"></i>More</Link>
                            <ul className="submenu">
                                <li className={this.state.SubTab == 'calendar' ? 'active':''} onClick={this.setActiveTab.bind(this, 'form','calendar','')}>
                                    <Link to="calendar">Calendar</Link>
                                </li>
                                <li className={this.state.SubTab == 'icon' ? 'has-submenu active':'has-submenu'} >
                                    <Link to="javascript:void(0);" id="my_href">Icons</Link>
                                    <ul className="submenu">
                                        <li className={this.state.MoreTab == 'material' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','material')}><Link to="icons-material">Material Design</Link></li>
                                        <li className={this.state.MoreTab == 'ion' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','ion')}><Link to="icons-ion">Ion Icons</Link></li>
                                        <li className={this.state.MoreTab == 'fontawesome' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','fontawesome')}><Link to="icons-fontawesome">Font Awesome</Link></li>
                                        <li className={this.state.MoreTab == 'themify' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','themify')}><Link to="icons-themify">Themify Icons</Link></li>
                                        <li className={this.state.MoreTab == 'dripicons' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','dripicons')}><Link to="icons-dripicons">Dripicons</Link></li>
                                        <li className={this.state.MoreTab == 'typicons' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','icon','typicons')}><Link to="icons-typicons">Typicons Icons</Link></li>
                                    </ul>
                                </li>
                                <li className={this.state.SubTab == 'table' ? 'has-submenu active':'has-submenu'} >
                                    <Link to="javascript:void(0);" id="my_href">Tables </Link>
                                    <ul className="submenu">
                                        <li className={this.state.MoreTab == 'basic' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','basic')}><Link to="tables-basic">Basic Tables</Link></li>
                                        <li className={this.state.MoreTab == 'datatable' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','datatable')}><Link to="tables-datatable">Data Table</Link></li>
                                        <li className={this.state.MoreTab == 'responsive' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','responsive')}><Link to="tables-responsive">Responsive Table</Link></li>
                                        <li className={this.state.MoreTab == 'editable' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','table','editable')}><Link to="tables-editable">Editable Table</Link></li>
                                </ul>
                                </li>
                                <li className={this.state.SubTab == 'map' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="my_href">Maps</Link>
                                    <ul className="submenu">
                                        <li className={this.state.MoreTab == 'google' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','map','google')}><Link to="google-map"> Google Map</Link></li>
                                        <li className={this.state.MoreTab == 'vector' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','map','vector')}><Link to="vector-map"> Vector Map</Link></li>
                                    </ul>
                                </li>
                                <li className={this.state.SubTab == 'session-timeout' ? 'active':''} onClick={this.setActiveTab.bind(this, 'more','session-timeout','')}>
                                    <Link to="ui-session-timeout">Session Timeout</Link>
                                </li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'chart' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-pie-chart"></i>Charts</Link>
                            <ul className="submenu">
                                <li className={this.state.SubTab == 'easy' ? 'active': ''} onClick={this.setActiveTab.bind(this, 'chart','easy','')}><Link to="easy-chart">Easy Chart</Link></li>
                                <li className={this.state.SubTab == 'canvas' ? 'active': ''} onClick={this.setActiveTab.bind(this, 'chart','canvas','')}><Link to="canvas-chart">Canvas Chart</Link></li>
                                <li className={this.state.SubTab == 'chartjs' ? 'active': ''} onClick={this.setActiveTab.bind(this, 'chart','chartjs','')}><Link to="chartjs-chart">Chartjs Chart</Link></li>
                            </ul>
                        </li>

                        <li className={this.state.Tab == 'pages' ? 'has-submenu active':'has-submenu'}>
                            <Link to="javascript:void(0);" id="my_href"><i className="ti-files"></i>Pages</Link>
                            <ul className="submenu megamenu">
                                <li>
                                    <ul>
                                        <li className={this.state.SubTab == 'timeline' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','timeline','')}><Link to="timeline">Timeline</Link></li>
                                        <li className={this.state.SubTab == 'invoice' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','invoice','')}><Link to="invoice">Invoice</Link></li>
                                        <li className={this.state.SubTab == 'directory' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','directory','')}><Link to="directory">Directory</Link></li>
                                        <li className={this.state.SubTab == 'login' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','login','')}><Link to="login">Login</Link></li>
                                        <li className={this.state.SubTab == 'register' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','register','')}><Link to="register">Register</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li  className={this.state.SubTab == 'recover-pass' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','recover-pass','')}><Link to="recover-pass">Recover Password</Link></li>
                                        <li  className={this.state.SubTab == 'lockscreen' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','lockscreen','')}><Link to="lockscreen">Lock Screen</Link></li>
                                        <li  className={this.state.SubTab == 'blank_page' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','blank_page','')}><Link to="blank_page">Blank Page</Link></li>
                                        <li  className={this.state.SubTab == 'error_404' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','error_404','')}><Link to="error_404">Error 404</Link></li>
                                        <li  className={this.state.SubTab == 'error_500' ? 'active':''} onClick={this.setActiveTab.bind(this, 'pages','error_500','')}><Link to="error_500">Error 500</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        */}
                    </ul>
                </div>
            </div> 
        </div> 
    </header>

        );
    }
}

export default TopChart;
