import { connect } from 'react-redux';
import Dashboard from './create';
import { createSwap } from '../../../redux/swaps/action'

const mapStateToProps = (store, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => ({
  createSwap: (amount, from, to, destination) => dispatch(createSwap(amount, from, to, destination)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
