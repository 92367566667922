import {
  FETCH_BALANCES,
  RECEIVE_BALANCES,
  RECEIVE_BALANCES_DONE,
  stakingApi
} from '../constants';
const axios = require('axios');

function getBalances() {
  return axios.get(`${stakingApi}/v1/floats`)
    .then((res) => {
      const balances = res.data.balances
      let balArr = []
      Object.keys(balances).forEach((sym) => {
        balArr.push({
          symbol: sym,
          value: balances[sym].confirmed || balances[sym],
        })
      })
      return balArr
    })
}

export const fetchBalances = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BALANCES })
    return getBalances()
      .then(function (balances) {
        dispatch({
          type: RECEIVE_BALANCES,
          payload: balances
        })
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
        dispatch({ type: RECEIVE_BALANCES_DONE })
      });
  }
}
