

import {
    SET_CURRENT_CURRENCY,
    FETCH_CONVERSION_RATES,
    RECEIVE_CONVERSION_RATES,
    RECEIVE_CONVERSION_RATES_DONE
  } from '../constants';

let initialState = {
    BTC: {
        ticker: 'BTC',
        symbol: "₿",
        rate: 1
    },
    USD: {
        ticker: 'USD',
        symbol: "$",
        rate: 0.0
    },
    EUR: {
        ticker: 'EUR',
        symbol: "€",
        rate: 0.0
    },
    JPY: {
        ticker: 'JPY',
        symbol: "¥",
        rate: 0.0
    },
    GBP: {
        ticker: 'GBP',
        symbol: "£",
        rate: 0.0
    },
}
initialState.currencies = Object.keys(initialState)
initialState.fetchingRates = false
initialState.currentCurrency = initialState.USD
  
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_CURRENCY:
            const ccy = initialState[action.currency]
            return {
                ...state,
                currentCurrency: ccy,
              }
        case FETCH_CONVERSION_RATES:
            return {
                ...state,
                fetchingRates: true
            }
        case RECEIVE_CONVERSION_RATES_DONE:
            return {
                ...state,
                fetchingRates: false
            }
        case RECEIVE_CONVERSION_RATES:
            const rates = action.rates
            rates.forEach((item) => {
                state[item.symbol].rate = item.rate
            })
            return {
                ...state,
                currentCurrency: state[state.currentCurrency.ticker]
            }
        default:
            return state
    }
}
  