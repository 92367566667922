import {
  RECEIVE_PEERS,
  FETCH_PEERS,
  RECEIVE_PEERS_DONE,
} from '../constants';

const initialState = {
  peers: [],
  isFetchingPeers: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PEERS:
      return {
        ...state,
        peers: action.peers,
        isFetchingPeers: false
      }
    case FETCH_PEERS:
      return {
        ...state,
        isFetchingPeers: true,
      }
    case RECEIVE_PEERS_DONE:
      return {
        ...state,
        isFetchingPeers: false
      }
    default:
      return state
  }
}
