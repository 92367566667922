import {
  RECEIVE_KVSTORE,
  FETCH_KVSTORE,
  RECEIVE_KVSTORE_DONE,
} from '../constants';

const initialState = {
  kvStore: {},
  isFetchingKvStore: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KVSTORE:
      return {
        ...state,
        isFetchingKvStore: true,
      }
    case RECEIVE_KVSTORE_DONE:
      return {
        ...state,
        isFetchingKvStore: false
      }
    case RECEIVE_KVSTORE:
      console.log("BOOOOOOOM", action)
      return {
        ...state,
        kvStore: action.payload
      }
    default:
      return state
  }
}
