import {
  RECEIVE_NETWORK_SWAPS,
  RECEIVE_NETWORK_SWAPS_DONE,
  FETCH_NETWORK_SWAPS,
  FETCH_SWAP_STATS,
  RECEIVE_SWAP_STATS,
  RECEIVE_SWAP_STATS_DONE
} from '../constants';

const initialState = {
  networkSwaps: [],
  stats: {},
  isFetchingSwapStats: false,
  isFetchingNetworkSwaps: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NETWORK_SWAPS:
      return {
        ...state,
        isFetchingNetworkSwaps: true
      }
    case RECEIVE_NETWORK_SWAPS:
      return {
        ...state,
        // networkSwaps: action.swaps,
        // not implemented yet
        // maybe store all page loads into memory?
        networkSwaps: action.swaps,
        isFetchingNetworkSwaps: false
      }
    case RECEIVE_NETWORK_SWAPS_DONE:
      return {
        ...state,
        isFetchingNetworkSwaps: false
      }
    case FETCH_SWAP_STATS:
      return {
        ...state,
        isFetchingSwapStats: true
      }
    case RECEIVE_SWAP_STATS:
      return {
        ...state,
        stats: action.stats,
        isFetchingSwapStats: false
      }
    case RECEIVE_SWAP_STATS_DONE:
      return {
        ...state,
        isFetchingSwapStats: false
      }
    default:
      return state
  }
}
