import { combineReducers } from 'redux'
import app from './app/reducer'
import tinychart from './tinychart/reducer'
import peers from './peers/reducer'
import swaps from './swaps/reducer'
import status from './status/reducer'
import explorers from './explorers/reducer'
import staking from './staking/reducer'
import conversion from './conversion/reducer'
import debug from './debug/reducer'

const reducers = combineReducers({
    app,
    tinychart,
    peers,
    swaps,
    status,
    explorers,
    staking,
    conversion,
    debug
});

export default reducers;
