import {
    SET_CURRENT_CURRENCY,
    FETCH_CONVERSION_RATES,
    RECEIVE_CONVERSION_RATES,
    RECEIVE_CONVERSION_RATES_DONE,
    getBtcFiatRates
} from '../constants';
const axios = require('axios')

export const setCurrency = (newCurrency) => {
  return (dispatch) => {
    // save to local storage
    localStorage.setItem(SET_CURRENT_CURRENCY, newCurrency);
    dispatch({
      type: SET_CURRENT_CURRENCY,
      currency: newCurrency
    })
  }
}

export const loadCurrency = () => {
  return (dispatch) => {
  // load from local storage
  const currency = localStorage.getItem(SET_CURRENT_CURRENCY);
  if (!currency) return
    dispatch({
      type: SET_CURRENT_CURRENCY,
      currency: currency
    })
  }
}

export const fetchRates = () => {
    return (dispatch) => {
      dispatch({ type: FETCH_CONVERSION_RATES })
      return axios.get(getBtcFiatRates)
        .then(function (response) {
          /*
          convert:
          [["tBTCUSD",6932,87.82290836,6933.9,60.19590798,47.4,0.0069,6931.5,4536.97535353,6944,6780]]
          into:
          { symbol: 'BTC', rate: 6931.5 }
          */
          let rates = []
          response.data.forEach((ticker) => {
            const symbol = ticker[0].substr(4, 7)
            rates.push({
              symbol,
              rate: ticker[7] // last price
            })
          })
          dispatch({
            type: RECEIVE_CONVERSION_RATES,
            rates
          })
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
        .finally(function () {
          // always executed
          dispatch({ type: RECEIVE_CONVERSION_RATES_DONE })
        });
    }
  }
