import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import reducers from './reducers';

function configureStore(initialState) {

    const middlewares = [thunk];

    return createStore(
        reducers,
        initialState,
        applyMiddleware(...middlewares)
    );
}

const Store = configureStore()
export default Store
