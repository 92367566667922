import {
  FETCH_NODE_INFO,
  RECEIVE_NODE_INFO,
  RECEIVE_NODE_INFO_DONE
} from '../constants';

const initialState = {
  node: {},
  isFetchingNodeInfo: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NODE_INFO:
      return {
        ...state,
        isFetchingNodeInfo: true
      }
    case RECEIVE_NODE_INFO:
      return {
        ...state,
        isFetchingNodeInfo: false,
        node: action.status
      }
    case RECEIVE_NODE_INFO_DONE:
      return {
        ...state,
        isFetchingNodeInfo: false
      }
    default:
      return state
  }
}
