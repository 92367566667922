
export const convertToCurrent = (rates, from, amount) => {
    // if not btc, get btc conversion
    const fromR = rates[from]
    const toR = rates.currentCurrency
    // convert to BTC
    const btcAmount = amount / fromR.rate
    console.log(`btcAmount, toR.rate`, btcAmount, toR.rate)
    return btcAmount * toR.rate
}

export const convertToCurrentStr = (rates, from, amount) => {
    return Number(convertToCurrent(rates, from, amount).toFixed(2)).toLocaleString()
}
