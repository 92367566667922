import {
  getApiUrl,
  FETCH_NODE_INFO,
  RECEIVE_NODE_INFO,
  RECEIVE_NODE_INFO_DONE
} from '../constants';
const axios = require('axios');

const V1_STATUS_ENDPOINT = 'api/v1/status'

export const fetchNodeStatus = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_NODE_INFO })
    return axios.get(`${getApiUrl()}/${V1_STATUS_ENDPOINT}`)
      .then(function (response) {
        dispatch({
          type: RECEIVE_NODE_INFO,
          status: response.data
        })
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
        dispatch({ type: RECEIVE_NODE_INFO_DONE })
      });
  }
}
