import React from 'react';
import Aux from '../../hoc/Aux_';
import Topchart from '../Topchart';
import Footer from '../Footer/Footer';

const layout = ( props ) => {

    return(
        <Aux>
        {!props.isloginpage ?
        <main>
         {props.topbar ? <Topchart /> : null}
            <div className="wrapper">
               {props.children}
            </div>
         {props.sidebar ? <Footer /> : null}
         </main>:props.children} 
         </Aux>  
    );
      
}


export default layout;
