import { connect } from 'react-redux';
import Dashboard from './dashboard'
import { fetchConnectedPeers } from '../../../redux/peers/action';
import { fetchSwapStats, fetchNetworkSwaps } from '../../../redux/swaps/action'
import { fetchNodeStatus } from '../../../redux/status/action'
import { fetchBalances } from '../../../redux/explorers/action'
import {
  fetchStakingLeaderboard,
  fetchRewardLeaderboard,
  fetchRewardHistory,
  fetchCurrentHoldings
} from '../../../redux/staking/action'
import {
  loadCurrency,
  fetchRates
} from '../../../redux/conversion/action'

const mapStateToProps = (store, ownProps) => {
  return {
    peers: store.peers.peers,
    swapStats: store.swaps.stats,
    nodeStatus: store.status.node,
    networkSwaps: store.swaps.networkSwaps,
    balances: store.explorers.balances,
    stakes: store.staking.stakes,
    rewards: store.staking.rewards,
    rewardHistory: store.staking.rewardHistory,
    currentHoldings: store.staking.currentHoldings,
    conversion: store.conversion
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchPeers: () => dispatch(fetchConnectedPeers()),
  fetchSwapStats: () => dispatch(fetchSwapStats()),
  fetchNodeStatus: () => dispatch(fetchNodeStatus()),
  fetchNetworkSwaps: (pageIndex, pageSize) => dispatch(fetchNetworkSwaps(pageIndex, pageSize)),
  fetchBalances: () => dispatch(fetchBalances()),
  fetchStakes: () => dispatch(fetchStakingLeaderboard()),
  fetchRewards: () => dispatch(fetchRewardLeaderboard()),
  fetchRewardsHistory: (address) => dispatch(fetchRewardHistory(address)),
  fetchCurrentHoldings: () => dispatch(fetchCurrentHoldings()),
  loadCurrency: () => dispatch(loadCurrency()),
  fetchConversionRates: () => dispatch(fetchRates()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
