import { connect } from 'react-redux';
import KvViewer from './viewer';
import { fetchKvStore } from '../../../redux/debug/action'

const mapStateToProps = (store, ownProps) => {
  return {
    kvStore: store.debug.kvStore
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchKvStore: () => dispatch(fetchKvStore()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KvViewer)
