import { connect } from 'react-redux';
import TopChart from './topchart'
import {
  setCurrency
} from '../../redux/conversion/action'

const mapStateToProps = (store, ownProps) => {
  return {
    nodeInfo: store.status,
    conversion: store.conversion,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrency: (ccy) => dispatch(setCurrency(ccy))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopChart)
