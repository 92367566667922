import React , { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
	Card,
  CardBody
} from 'reactstrap';

const blackListedAddresses = [
  'tbnb106884cmlet682kyt8jjxfpqx887q93hujjwt4x'
]

class TokenEconomics extends Component{

render(){
  let chartData = []
  let chartLabels = []
  let tokensInCirculation = 0
  let aggregatedQuantity = 0
  let maxChartLabels = 4
  Object.keys(this.props.holdings).forEach((key) => {
    const holding = this.props.holdings[key]
    if (blackListedAddresses.includes(key)) {
      return
    }
    tokensInCirculation += holding.quantity
    if (chartData.length < maxChartLabels) {
      chartData.push({
        quantity: holding.quantity,
        label: key
      })
    } else {
      aggregatedQuantity += holding.quantity
    }
  })
  chartData.push({
    quantity: aggregatedQuantity,
    label: 'Other'
  })
  const data = {
    labels: chartLabels,
    datasets: [{
      data: chartData.map((x) => x.quantity),
      backgroundColor: [
        '#7a6fbe',
        '#29bbe3',
        '#f4c63d'
      ],
      hoverBackgroundColor: [
        '#7a6fbe',
        '#29bbe3',
        '#f4c63d'
      ]
    }]
  }
  const option = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var total = tokensInCirculation;
          var currentValue = chartData[tooltipItem.index].quantity;
          var percentage = parseFloat((currentValue/total*100).toFixed(1));
          return `${currentValue} (${percentage}%)`;
        },
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  }
  return (
    <div className="col-xl-3">
        <div className="card m-b-20">
            <div className="card-body">
                <h4 className="mt-0 header-title">Network economics</h4>
                <div className="row text-center m-t-20">
                    <div className="col-6">
                        <h5 className="">{Number(tokensInCirculation.toFixed(2)).toLocaleString()}</h5>
                        <p className="text-muted">Swingby Tokens</p>
                    </div>
                    <div className="col-6">
                        <h5 className="">{Object.keys(this.props.holdings).length.toLocaleString()}</h5>
                        <p className="text-muted">Active Addresses</p>
                    </div>
                </div>
                <div id="morris-donut-example" className="mt-4">
                  <Doughnut height={250} data={data} options={option} />
                </div>
            </div>
        </div>
    </div>
    );
  }
}

TokenEconomics.defaultProps = {
  holdings: {}
}

export default TokenEconomics;   
