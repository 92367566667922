import React , { Component } from 'react';
import {
	Card,
  CardBody
} from 'reactstrap';
import { Bar } from 'react-chartjs-2';

function GetMonthName(monthNumber) {
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (monthNumber < 0) {
    monthNumber = monthNumber + 12
  }
  return months[monthNumber];
}

class Earnings extends Component{

render(){
  const monthNum = new Date().getMonth()
  // use slice() to get new copy of array
  // const networkRewardsVol = (this.props.stats.networkRewards1mVolume || []).slice().reverse()
  // const rewardsVol = (this.props.stats.rewards1mVolume || []).slice().reverse()
  // const yourEarnings = rewardsVol[rewardsVol.length -1] || 0
  // const networkEarnings = networkRewardsVol[networkRewardsVol.length -1] || 0
  let chartData = new Array(0).fill(4)
  let dataLen = this.props.rewardHistory.length > 4 ? 4 : this.props.rewardHistory.length
  for (let i = 0; i < dataLen; i++) {
    if (this.props.rewardHistory[i]) {
      chartData[i] = this.props.rewardHistory[i]
    }
  }
  const data = {
    labels: [
      'This week',
      'Last week',
      '2 weeks ago',
      '1 month ago'
    ],
    datasets: [
      {
          label: 'Monthly Earnings',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,195,192,0.4)',
          borderColor: 'rgba(75,196,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'round',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 50,
          data: chartData.reverse()
      }
    ]
  };
  let totalRewards = 0
  this.props.rewards.forEach((reward) => totalRewards =  totalRewards + Number(reward.reward))
  return (
    <div className="col-xl-3">
        <Card>
            <CardBody>
                <div className="row">
                  <div className="col-6">
                    <h4 className="mt-0 header-title mb-1">Your Earnings</h4>
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <a href="https://bridge-testnet.swingby.network/explorer">View leaderboard ></a>
                  </div>
                </div>

                <div className="row text-center m-t-20 mb-3">
                    <div className="col-6">
                        <h5 className="">{(Number(this.props.nodeReward.reward || 0).toFixed(2) || 0).toLocaleString()} SWINGBY</h5>
                        <p className="text-muted">This Node</p>
                    </div>
                    <div className="col-6">
                        <h5 className="">{Number(totalRewards.toFixed(2)).toLocaleString()} SWINGBY</h5>
                        <p className="text-muted">Entire Network</p>
                    </div>
                </div>

                <div id="morris-bar-stacked" className="dashboard-charts morris-charts">
                  <Bar
                    data={data}
                    width={100}
                    height={320}
                    options={{ maintainAspectRatio: false  }}
                 />
                </div>
                
            </CardBody>
        </Card>
    </div>
    );
  }
}

Earnings.defaultProps = {
  stats: {},
  rewardHistory: [],
  rewards: [],
  nodeReward: {
    reward: 0
  }
}

export default Earnings;   
