import React from "react";

import {
	Card,
	CardBody
} from 'reactstrap';

function truncateText (text, maxLength=25) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength-3) + '...'
  }
  return text
}

const states = {
  0:  {
    label: 'None',
    color: '#ec536c'
  },
  1: {
    label: 'Discovery',
    color: '#267ded'
  },
  2: {
    label: 'Signing',
    color: '#58db83'
  },
  3: {
    label: 'Keygen',
    color: '#f5b225'
  },
  4: {
    label: 'Re-share',
    color: '#04073e'
  }
}

const monikerToUrl = (moniker) => {
  if (moniker === "testnet_node_1") {
    return "https://testnet-node.swingby.network"
  } else {
    return `https://${moniker.replace('_', '-')}.swingby.network`
  }
}

class PeerList extends React.Component {
	render() {
		return (
      <Card className="m-b-20" style={{ height: 490 }}>
        <CardBody>
            <h4 className="mt-0 header-title mb-4">Peers</h4>
            <div className="inbox-wid scrollbar" style={{ overflowY: "scroll", overflowX: 'hidden', height: 410 }}>
            <table className="table mb-0">
              <tbody>
                  {
                    this.props.peers.map((peer) => {
                      let stakedCollateral = 0
                      this.props.stakes.forEach((stake) => {
                        if (stake.address === peer.stake.address) {
                          stakedCollateral = stake.stake
                        }
                      })
                      const listenAddr = truncateText(peer.id, 40)
                      return (
                        <tr key={peer.moniker}>
                          <td style={{ padding: 5 }}>
                            <div className="row" style={{ padding: 0, margin: 0}}>
                              <h6 className="inbox-item-author mt-0 mb-1"><b>{peer.moniker}</b> <span className="text-muted ml-2">v{peer.version}</span></h6>
                              <p className="inbox-item-text text-muted mb-0">{listenAddr}</p>
                            </div>
                          </td>
                          <td>
                            <a type="button" href={monikerToUrl(peer.moniker)} className="btn btn-light waves-light waves-effect"><i className="ti ti-dashboard"></i></a>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>  
            </div>  
        </CardBody>
    </Card>
		);
	}
}

PeerList.defaultProps = {
  peers: []
}

export default PeerList;
