import {
  RECEIVE_STAKING_LEADERBOARD_DONE,
  RECEIVE_STAKING_LEADERBOARD,
  FETCH_STAKING_LEADERBOARD,
  RECEIVE_REWARDS_LEADERBOARD_DONE,
  RECEIVE_REWARDS_LEADERBOARD,
  FETCH_REWARDS_LEADERBOARD,
  FETCH_REWARDS_HISTORY,
  RECEIVE_REWARDS_HISTORY,
  RECEIVE_REWARDS_HISTORY_DONE,
  FETCH_CURRENT_HOLDINGS,
  RECEIVE_CURRENT_HOLDINGS,
  RECEIVE_CURRENT_HOLDINGS_DONE,
  stakingApi,
  stakingProdApi
} from '../constants';
const axios = require('axios');

const V1_STAKES_LEADERBOARD = 'v1/stakes/leaderboard'
const V1_REWARDS_LEADERBOARD = 'v1/stakes/rewards_leaderboard'
const V1_REWARDS_HISTORY = 'v1/stakes/rewards_history'
const V1_HOLDINGS = `v1/stakes/holders`

export const fetchStakingLeaderboard = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STAKING_LEADERBOARD })
    return axios.get(`${stakingProdApi}/${V1_STAKES_LEADERBOARD}?page_size=999999`)
    .then((results) => {
      dispatch({
        type: RECEIVE_STAKING_LEADERBOARD,
        payload: results.data.items
      })
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    })
    .finally(function () {
      // always executed
      dispatch({ type: RECEIVE_STAKING_LEADERBOARD_DONE })
    });
  }
}

export const fetchCurrentHoldings = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_CURRENT_HOLDINGS })
    return axios.get(`${stakingProdApi}/${V1_HOLDINGS}`)
    .then((results) => {
      dispatch({
        type: RECEIVE_CURRENT_HOLDINGS,
        payload: results.data
      })
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    })
    .finally(function () {
      // always executed
      dispatch({ type: RECEIVE_CURRENT_HOLDINGS_DONE })
    });
  }
}

export const fetchRewardLeaderboard = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_REWARDS_LEADERBOARD })
    return axios.get(`${stakingApi}/${V1_REWARDS_LEADERBOARD}?page_size=999999`)
    .then((results) => {
      dispatch({
        type: RECEIVE_REWARDS_LEADERBOARD,
        payload: results.data.items
      })
      return results.data.items
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    })
    .finally(function () {
      // always executed
      dispatch({ type: RECEIVE_REWARDS_LEADERBOARD_DONE })
    });
  }
}

export const fetchRewardHistory = (address) => {
  return (dispatch) => {
    dispatch({ type: FETCH_REWARDS_HISTORY })
    return axios.get(`${stakingApi}/${V1_REWARDS_HISTORY}?address=${address}`)
    .then((results) => {
      dispatch({
        type: RECEIVE_REWARDS_HISTORY,
        payload: results.data
      })
      return results.data
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    })
    .finally(function () {
      // always executed
      dispatch({ type: RECEIVE_REWARDS_HISTORY_DONE })
    });
  }
}
