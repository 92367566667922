import React , { Component } from 'react';
import { AreaChart } from 'react-easy-chart';
import AUX from '../../hoc/Aux_';

class SwapVolumeChart extends Component{

render(){
  const data1 = this.props.volume1.map((vol, index) => {
    return { x: index + 1, y: vol }
  })
  const data2 = this.props.volume2.map((vol, index) => {
    return { x: index + 1, y: vol }
  })
  return (
      <AUX>
        <AreaChart
          axes
          margin={{top: 10, right: 10, bottom: 50, left: 50}}
          axisLabels={{x: 'Days', y: 'Volume ($)'}}
          width={this.props.width}
          height={this.props.height}
          interpolate={'cardinal'}
          areaColors={['#6dd6cf', '#a6ade4']}
          data={[ data1, data2 ]}
        />
      </AUX>
    );
  }
}

SwapVolumeChart.defaultProps = {
  volume1: [],
  volume2: [],
  width: 700,
  height: 240
}

export default SwapVolumeChart;   
