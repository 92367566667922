import {
  RECEIVE_STAKING_LEADERBOARD_DONE,
  RECEIVE_STAKING_LEADERBOARD,
  FETCH_STAKING_LEADERBOARD,
  RECEIVE_REWARDS_LEADERBOARD_DONE,
  RECEIVE_REWARDS_LEADERBOARD,
  FETCH_REWARDS_LEADERBOARD,
  FETCH_REWARDS_HISTORY,
  RECEIVE_REWARDS_HISTORY,
  RECEIVE_REWARDS_HISTORY_DONE,
  FETCH_CURRENT_HOLDINGS,
  RECEIVE_CURRENT_HOLDINGS,
  RECEIVE_CURRENT_HOLDINGS_DONE
} from '../constants';

const initialState = {
  isLoadingStakingLeaderboard: false,
  isLoadingRewardLeaderboard: false,
  isLoadingRewardHistory: false,
  isLoadingCurrentHoldings: true,
  currentHoldings: {},
  rewardHistory: [],
  stakes: [],
  rewards: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAKING_LEADERBOARD:
      return {
        ...state,
        isLoadingStakingLeaderboard: true,
      }
    case RECEIVE_STAKING_LEADERBOARD:
      return {
        ...state,
        stakes: action.payload,
      }
    case RECEIVE_STAKING_LEADERBOARD_DONE:
      return {
        ...state,
        isLoadingStakingLeaderboard: false
      }
    case FETCH_REWARDS_LEADERBOARD:
      return {
        ...state,
        isLoadingRewardLeaderboard: true,
      }
    case RECEIVE_REWARDS_LEADERBOARD:
      return {
        ...state,
        rewards: action.payload,
      }
    case RECEIVE_REWARDS_LEADERBOARD_DONE:
      return {
        ...state,
        isLoadingRewardLeaderboard: false
      }
    case FETCH_REWARDS_HISTORY:
      return {
        ...state,
        isLoadingRewardHistory: true,
      }
    case RECEIVE_REWARDS_HISTORY:
      return {
        ...state,
        rewardHistory: action.payload,
      }
    case RECEIVE_REWARDS_HISTORY_DONE:
      return {
        ...state,
        isLoadingRewardHistory: false
      }
    case FETCH_CURRENT_HOLDINGS:
      return {
        ...state,
        isLoadingCurrentHoldings: true,
      }
    case RECEIVE_CURRENT_HOLDINGS:
      return {
        ...state,
        currentHoldings: action.payload
      }
    case RECEIVE_CURRENT_HOLDINGS_DONE:
      return {
        ...state,
        isLoadingCurrentHoldings: false
      }
    default:
      return state
  }
}
