import React from "react";

import {
	Card,
  CardBody,
  Row
} from 'reactstrap';
import {
  convertToCurrentStr
} from '../../redux/conversion/utils'

class Stats extends React.Component {
	render() {
    let networkSwaps24hr = 0
    if (this.props.swapStats.network24hrSwaps) {
      networkSwaps24hr = this.props.swapStats.network24hrSwaps[0] || 0
    }
    let networkSwapsVolume24hr = (this.props.swapStats.networkSwapsVolume || 0)
    if (this.props.swapStats.network24hrSwapsVolume) {
      networkSwapsVolume24hr = (this.props.swapStats.network24hrSwapsVolume[0] || 0)
    }
    let participantSwaps24hr = 0
    if (this.props.swapStats.participated24hrSwaps) {
      participantSwaps24hr = this.props.swapStats.participated24hrSwaps[0] || 0
    }
    let participantSwapsVolume24hr = 0
    if (this.props.swapStats.participated24hrSwapsVolume) {
      participantSwapsVolume24hr = (this.props.swapStats.participated24hrSwapsVolume[0] || 0)
    }
    let stakedCollateral = 0
    if (this.props.nodeStatus.swapInfo) {
      stakedCollateral = (this.props.nodeStatus.swapInfo.stakeAmount || 0).toLocaleString()
    }
    let participationReward24hr = 0
    if (this.props.swapStats.rewards24hrVolume) {
      participationReward24hr = (this.props.swapStats.participationReward24hr || 0).toLocaleString()
    }
    let nodeVersion = "Unknown"
    if (this.props.nodeStatus.nodeInfo) {
      nodeVersion = "v" + this.props.nodeStatus.nodeInfo.version
    }
    let moniker = "Unknow"
    if (this.props.nodeStatus.nodeInfo) {
      moniker = this.props.nodeStatus.nodeInfo.moniker
    }
    let totalStaked = 0
    this.props.stakes.forEach((stake) => {
      totalStaked = totalStaked += Number(stake.stake)
    })
    const sym = this.props.conversion.currentCurrency.symbol
		return (
      <Row>
        <div className="col-xl-3 col-md-6">
              <Card className=" mini-stat bg-primary">
                  <CardBody className="mini-stat-img">
                      <div className="mini-stat-icon">
                          <i className="ion ion-ios7-people float-right"></i>
                      </div>
                      <div className="text-white">
                          <h6 className="text-uppercase" style={{ marginBottom: 21 }}>Connected Peers</h6>
                          <h4 className="mb-4" >{this.props.peers.length}</h4>
                          <span className="badge badge-success"> {nodeVersion} </span> <span className="ml-2">Connected to <b>{moniker}</b></span>
                      </div>
                  </CardBody>
              </Card>
          </div>
          <div className="col-xl-3 col-md-6">
              <Card className="mini-stat bg-primary">
                  <CardBody className="mini-stat-img">
                      <div className="mini-stat-icon">
                          <i className="ion ion-arrow-swap float-right"></i>
                      </div>
                      <div className="text-white">
                          <h6 className="text-uppercase mb-2">Swaps <span style={{ fontSize: 12, opacity: 0.5 }}>24hr</span></h6>
                          <p style={{ marginTop: 10, marginBottom: 0, opacity: 0.75 }}>{networkSwaps24hr}</p>
                          <h6 className="mt-0" style={{marginBottom: 17}}>{sym}  {convertToCurrentStr(this.props.conversion, 'BTC', networkSwapsVolume24hr)}</h6>
                          <span className="badge badge-danger"> -29% </span> <span className="ml-2">Since yesterday</span>
                      </div>
                  </CardBody>
              </Card>
          </div>
          <div className="col-xl-3 col-md-6">
              <Card className="mini-stat bg-primary">
                  <CardBody className="mini-stat-img">
                      <div className="mini-stat-icon">
                          <i className="ion ion-connection-bars float-right"></i>
                      </div>
                      <div className="text-white">
                          <h6 className="text-uppercase mb-2">Participations <span style={{ fontSize: 12, opacity: 0.5 }}>24hr</span></h6>
                          <p style={{ marginTop: 10, marginBottom: 0, opacity: 0.75 }}>{participantSwaps24hr}</p>
                          <h6 className="mt-0" style={{marginBottom: 17}}>{sym} {convertToCurrentStr(this.props.conversion, 'BTC', participantSwapsVolume24hr)}</h6>
                          <span className="badge badge-info"> {participationReward24hr} SWINGBY </span> <span className="ml-2">Rewarded today</span>
                      </div>
                  </CardBody>
              </Card>
          </div>
          <div className="col-xl-3 col-md-6">
              <Card className="mini-stat bg-primary">
                  <CardBody className="mini-stat-img">
                      <div className="mini-stat-icon">
                          <i className="fas fa-money-bill-wave-alt float-right"></i>
                      </div>
                      <div className="text-white">
                          <h6 className="text-uppercase" style={{ marginBottom: 21 }}>Collateral Staked</h6>
                          <h4 className="mb-4">{Number(totalStaked.toFixed(2)).toLocaleString()} SWINGBY</h4>
                          <span className="badge badge-info"> +0% </span> <span className="ml-2">From previous period</span>
                      </div>
                  </CardBody>
              </Card>
          </div>
      </Row>
		);
	}
}

Stats.defaultProps = {
  swapStats: {},
  peers: [],
  nodeStatus: {},
  stakes: []
}

export default Stats;
