import {
  getApiUrl,
  FETCH_PEERS,
  RECEIVE_PEERS,
  RECEIVE_PEERS_DONE
} from '../constants';
const axios = require('axios');

const V1_PEERS_ENDPOINT = 'api/v1/peers'

export const fetchConnectedPeers = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PEERS })
    return axios.get(`${getApiUrl()}/${V1_PEERS_ENDPOINT}`)
      .then(function (response) {
        dispatch({
          type: RECEIVE_PEERS,
          peers: response.data
        })
        return response.data
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
        dispatch({ type: RECEIVE_PEERS_DONE })
      });
  }
}
