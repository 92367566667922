import React , { Component } from 'react';
import PeerList from '../../../components/Peers/PeerList'
import Stats from '../../../components/Dashboard/Stats'
import VerticalStats from '../../../components/Dashboard/VerticalStats'
import SwapVolume from '../../../components/Dashboard/SwapVolume'
import RecentSwaps from '../../../components/Dashboard/RecentSwaps'
import Earnings from '../../../components/Dashboard/Earnings'
import TokenEconomics from '../../../components/Dashboard/TokenEconomics'
import AUX from '../../../hoc/Aux_';

class Dashboard extends Component{

  refresh = () => {
    this.props.fetchPeers()
    this.props.fetchSwapStats()
    this.props.fetchNetworkSwaps(0, 100)
    this.props.fetchStakes()
    this.props.fetchRewards()
    this.props.fetchNodeStatus()
    this.props.fetchCurrentHoldings()
    this.props.loadCurrency()
    this.props.fetchConversionRates()
  }

  componentDidMount() {
    this.props.fetchBalances()
    this.props.fetchPeers()
      .then((peers) => {
        if (!peers) {
          return
        }
        let nodeAddress = ""
        peers.forEach((peer) => {
          if (peer.thisNode) {
            nodeAddress = peer.stake.address
          }
        })
        if (nodeAddress !== "") {
          this.props.fetchRewardsHistory(nodeAddress)
        }
      })
    // refresh all redux data every 5 seconds
    this.refresh()
    setInterval(this.refresh(), 5000)
	}

  render() {
    let nodeAddress = ""
    this.props.peers.forEach((peer) => {
      if (peer.thisNode) {
        nodeAddress = peer.stake.address
      }
    })
    let nodeStake = {}
    this.props.stakes.forEach((stake) => {
      if (stake.address === nodeAddress) {
        nodeStake = stake
      }
    })
    let nodeReward = {
      rewards: 0
    }
    this.props.rewards.forEach((reward) => {
      if (reward.address === nodeAddress) {
        nodeReward = reward
      } 
    })
    return (
      <AUX>
        <div className="container-fluid"> 
              <Stats
                conversion={this.props.conversion}
                peers={this.props.peers}
                swapStats={this.props.swapStats}
                nodeStatus={this.props.nodeStatus}
                stakes={this.props.stakes}
              />
              <div className="row">
                
                  <TokenEconomics holdings={this.props.currentHoldings} />

                  <SwapVolume
                    conversion={this.props.conversion}
                    stats={this.props.swapStats}
                  />

                  <Earnings
                    stats={this.props.swapStats}
                    stakes={this.props.swapStats}
                    nodeStake={nodeStake}
                    rewards={this.props.rewards}
                    nodeReward={nodeReward}
                    rewardHistory={this.props.rewardHistory}
                  />

              </div>
              
              <div className="row">
                <div className="col-xl-4 col-lg-6">
                  <RecentSwaps
                    conversion={this.props.conversion}
                    swaps={this.props.networkSwaps}
                  />
                </div>

                <div className="col-xl-4 col-lg-6">
                  <PeerList peers={this.props.peers} stakes={this.props.stakes}/>
                </div>

                <div className="col-xl-4 col-md-6">
                  <VerticalStats
                    balances={this.props.balances}
                    swaps={this.props.networkSwaps}
                  />
                </div>
              </div>
            </div>
          </AUX>
    );
  }
}

export default Dashboard;   
