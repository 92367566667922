import React from 'react';
import Copyright from './Copyright'

const footer = ( props ) => (
    <footer className="footer">
    <Copyright/>
</footer>  
);

export default footer;
