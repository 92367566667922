import React , {Component } from 'react';
import ReactJson from 'react-json-view'
import AUX from '../../../hoc/Aux_';


class KvViewer extends Component{

  componentDidMount() {
    this.props.fetchKvStore()
  }

  render() {
    console.log("***", this.props)
    return (
      <AUX>
        <div className="container-fluid"> 
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ marginBottom: 0 }}>
                  <div className="card-body">

                      <h4 className="mt-0 header-title">KV Store Viewer</h4>
                      <p className="text-muted m-b-30 font-14">Initiate a trustless swap and convert your coins
                        to/from a Swingby tethered equivalent.</p>

                      <ReactJson
                        style={{ padding: 20 }}
                        src={this.props.kvStore}
                        theme="monokai"
                      />

                  </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default KvViewer;   
