import {
    TOP_BAR,
    LOGINPAGE,
    SIDE_BAR,
    REG_PAGE,
    SWITCHTOLOGIN,
    SWITCHTOREG,
    RECOVER_PASS,
} from '../constants';

const initialState = {
    top_bar: true,
    side_bar: true,
    loginpage: false,
    isRegpage: false,
    recover_pass: false
}

export default (state = initialState, action) => {
    switch(action.type){
        case TOP_BAR: 
        return{
            ...state,
            top_bar:!state.top_bar
        };
        case SIDE_BAR:
        return{
            ...state,
            side_bar:!state.side_bar
        };
        case LOGINPAGE:
        return{
            ...state,
            loginpage:!state.loginpage
        };
        case REG_PAGE:
        return{
            ...state,
            isRegpage:!state.isRegpage
        };
        case SWITCHTOLOGIN:
        return{
            ...state,
           isRegpage:!state.isRegpage,
        };
        case SWITCHTOREG:
        return{
            ...state,
            isRegpage:!state.isRegpage
        };
        case RECOVER_PASS:
        return{
            ...state,
            recover_pass:!state.recover_pass
        };
        default :
        return state;
    }

}
